<template>
  <div class="footer container-fluid">
    <div class="row">
      <!--
      <div class="col-sm text-start ms-sm-5 ps-sm-5">
        <h1 class="fw-bold cname">Interio Inc.</h1>
        <div class="row ms-sm-5 text-center">
          <div class="col-sm-2 fs-1" v-for="ln in socialLinks">
            <a href="{{ln.link}}"
              ><fa
                :icon="ln.icon"
                class="text-primary p-2 bg-light shadow rounded-circle"
            /></a>
          </div>
        </div>
      </div>
          -->
      <div class="col-sm">
        <div class="row row-cols-1 text-start">
          <!--
          <div class="col text-dark">
            Location:
            <a
              class="text-secondary text-decoration-none"
              target="_blank"
              href="https://www.google.com/maps/@16.0784547,108.2076898,18.25z"
              >Kitamachi Warabi Saitama, Japan</a
            >
          </div>
          <div class="col mt-3 text-dark">
            <address>
              <p>
                Email:<a
                  class="text-secondary ms-2 text-decoration-none text-break"
                  href="mailto:inquiry@interio-inc.com"
                  >inquiry@interio-inc.com</a
                >
              </p>
            </address>
          </div>
          -->
          <!-- 
          <div class="col text-dark">
            Call us at :
            <a
              class="text-secondary text-decoration-none"
              href="tel:+840889512609"
              >+84-89-51-2609</a
            >
          </div>
          -->
        </div>
      </div>
      <!--
      <div class="col-sm">
        <div class="row">
          <div class="col">
            <div class="container p-4 pb-0">
              <section class="">
                <form @submit.prevent>
                  <div class="row d-flex justify-content-center">
                    <div class="col-auto">
                      <p class="fs-5 text-dark">Sign up for our newsletter</p>
                    </div>
                    <div class="col-md-5 col-12">
                      <div class="form-outline mb-4">
                        <input
                          type="email"
                          id="form5Example27"
                          class="form-control"
                          placeholder="Email adress"
                        />
                      </div>
                    </div>
                    <div class="col-auto">
                      <button
                        type="submit"
                        class="btn btn-pink mb-4 text-light"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
        -->
      <div class="text-center p-3">© {{ date }} Copyright Interio Inc.</div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  name: "foot",
  setup() {
    //Copyright Date
    const date = ref(new Date().getFullYear());
    return {
      date,
    };
  },
  data() {
    return {
      socialLinks: [
        {
          link: "#",
          icon: ["fab", "facebook"],
        },
        {
          link: "#",
          icon: ["fab", "twitter"],
        },
        {
          link: "#",
          icon: ["fab", "github"],
        },
        {
          link: "#",
          icon: ["fab", "linkedin"],
        },
      ],
    };
  },
};
</script>

<style scoped>
.cname {
  color: #da9dc0;
}
.p-more {
  padding-top: 200px;
}
.btn-pink {
  background-color: #667eea;
}
.btn-pink:hover {
  background-color: #ed64a6;
}
.tw {
  color: rgb(0, 162, 255);
}
.footer {
  background-image: linear-gradient(to right, #3f5a69, rgb(30, 57, 94));
  color: #fff;
  a {
    color: #fff;
  }
}
</style>
