<template>
  <nav class="navbar navbar-expand-sm navbar-light fixed-top bgwhite py-1">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mynavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="mynavbar">
        <div class="logo-left container-fluid">
          <img src="/logo.svg" />
        </div>

        <h3>Interio Inc.</h3>
        <ul class="navbar-nav me-auto ps-sm-5 ms-sm-5">
          <!-- Home -->
          <li class="nav-item">
            <router-link
              to="/"
              class="nav-link fw-bold"
              tag="button"
              active-class="active"
              >Home</router-link
            >
          </li>
          <!--
          <li class="nav-item">
            <router-link
              to="/Services"
              class="nav-link fw-bold"
              tag="button"
              active-class="active"
              >Services & Contents
            </router-link>
          </li>
          -->
          <li class="nav-item">
            <router-link
              to="/Contact"
              class="nav-link fw-bold"
              tag="button"
              active-class="active"
              >Contact Us</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              to="/about"
              class="nav-link fw-bold"
              tag="button"
              active-class="active"
              >About Us</router-link
            >
          </li>
        </ul>
        <!--
        <div class="d-sm-flex text-center pe-sm-5 me-sm-5 my-4 my-md-0">
          <a href="#" class="mx-4"
            ><fa :icon="['fab', 'facebook']" class="fa fb"
          /></a>
          <a href="#" class="mx-4"
            ><fa :icon="['fab', 'twitter']" class="fa tw"
          /></a>
          <a href="#" class="mx-4"
            ><fa :icon="['fab', 'github']" class="fa gh"
          /></a>
        </div>
        <div class="btn-group">
          <router-link to="/auth/login" class="btn btn hpink" tag="button"
            >Sign in</router-link
          <router-link to="/auth/login" class="btn btn hblue" tag="button"
            >Sign Up</router-link
          >
        </div>  -->
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "navbar",
  setup() {},
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Cabin&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@500&display=swap");

nav.navbar {
  background-color: #57929d;
}
.logo-left {
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  img {
    width: 60px;
    height: 60px;
  }
}

.bgwhite {
  background-color: white;
}
.hpink:hover {
  background: #ed64a6;
  color: white;
}
.hblue:hover {
  background: #667eea;
  color: white;
}
nav {
  box-shadow: 0px 0.5px 10px rgb(180, 180, 180);
}
.fa {
  color: #94a3b8;
  font-size: 2.6vh;
}
.fa:hover {
  animation-name: example;
  animation-duration: 2s;
}
.fb:hover {
  color: rgb(0, 102, 255);
}
.tw:hover {
  color: rgb(0, 162, 255);
}
.gh:hover {
  color: black;
}
@keyframes example {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-item a.router-link-exact-active {
  text-shadow: 1px 8px 9px rgb(41, 40, 40);
  font-family: "Maven Pro", sans-serif;
}
H3 {
  margin-top: 8px;
  margin-left: 15px;
  color: #fff;
  font-weight: bold;
  font-size: 34px;
  font-family: "Cabin", sans-serif;
}
</style>
