<template>
  <div class="wrapper">
    <div>
      <!-- navbar -->
      <navbar />
    </div>

    <div>
      <!-- body -->
      <router-view />
    </div>
  </div>
  <foot :class="'footer ' + display" />
</template>

<script>
import navbar from "../components/navbar.vue";
import foot from "../components/foot.vue";
export default {
  name: "index",
  setup() {
    return {};
  },
  components: {
    navbar,
    foot,
  },
};
</script>

<style>
body {
  background-image: linear-gradient(to right, #ffffff, rgb(201, 168, 188));
}
.wrapper {
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
}
.footer {
}
</style>
